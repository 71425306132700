import 'application/src/scss/analytics/product-click-stats.scss';

import {
  scrollIntoView
} from '../functions.js';

/**
 * Class for loading & displaying click stats for products
 * @class
 */
class ProductClickStats {
  constructor(args) {
    this.translations = args.translations;
    this.maxEntries = args.maxEntries || 10;
    this.outerContainer = document.querySelector('.product-click-stats');
    if (!this.outerContainer) return;
    this.dataHead = this.outerContainer.querySelector('.data-head');
    this.productRows = this.outerContainer.querySelector('.product-rows');
    this.loader = this.outerContainer.querySelector('.uic-spinner-overlay');
    this.showMoreButton = this.outerContainer.querySelector('.show-more');
    this.showLessButton = this.outerContainer.querySelector('.show-less');
    this.noResultsError = this.outerContainer.querySelector('.no-results-error');
    this.hideClass = 'hide';

    this.showLoader();

    this.loadProducts()
      .then(response => {
        this.data = response.clicks;

        let allClicks = 0;
        let max = 0;

        this.data.map(data => {
          const amount = parseInt(data.amount);
          allClicks += amount;
          max = max < amount ? amount : max;
        });

        this.data.map(data => {
          const amount = parseInt(data.amount);
          data.width = amount / (max / 100);
          data.pct = (amount / (allClicks / 100)).toFixed(2);
        });

        this.data.sort((a, b) => {
          return b.amount - a.amount;
        });

        this.render(this.maxEntries);
        this.addEvents();
        this.hideLoader();
      })
      .catch(e => {
        console.log(e);
      });
  }

  addEvents() {
    if (this.showMoreButton) {
      this.showMoreButton.addEventListener('click', e => {
        this.render();
        this.showMoreButton.classList.add('hide');
        this.showLessButton.classList.remove('hide');
        this.scrollToTop();
      });
    }

    if (this.showMoreButton) {
      this.showLessButton.addEventListener('click', e => {
        this.render(this.maxEntries);
        this.showLessButton.classList.add('hide');
        this.showMoreButton.classList.remove('hide');
        this.scrollToTop();
      });
    }
  }

  scrollToTop() {
    if (window.pageYOffset > this.outerContainer.offsetTop) scrollIntoView(this.outerContainer);
  }

  loadProducts() {
    return new Promise((resolve, reject) => {

      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4 && xhr.status == 200) resolve(JSON.parse(xhr.responseText));
      };

      xhr.open('POST', '/app/ajax/loadClickStatistics', true);
      xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      xhr.send();
    });
  }

  showLoader() {
    this.loader.style.display = 'flex';
  }

  hideLoader() {
    this.loader.style.display = 'none';
  }

  render(maxEntries) {
    this.productRows.innerHTML = '';

    if (this.data.length) {
      const max = maxEntries || this.data.length;

      for (let i = 0; i < max; i++) {
        const productData = this.data[i];

        if (productData) {
          const row = document.createElement('div');
          this.productRows.insertAdjacentElement('beforeend', row);

          new ProductStatisticRow({
            parent: this,
            element: row,
            data: productData,
            translations: this.translations
          });
        }
      }

      this.dataHead.classList.remove(this.hideClass);
    } else {
      this.noResultsError.classList.remove(this.hideClass);
    }
  }
}

/**
 * Main Controller Class for Dedere Analytics
 * @class
 * @param { object } args.parent - The instance of the Analytics class
 * @param { object } args.element - The product row element
 * @param { object } args.data - The product data JSON
 */
class ProductStatisticRow {
  constructor(args) {
    this.parent = args.parent;
    this.element = args.element;
    this.translations = args.translations;
    this.element.classList.add('data-row');
    this.data = args.data;

    this.render();
  }

  render() {
    this.element.innerHTML = this.template;
  }

  get template() {
    return `
      <div>${this.data.basicname}</div>
      <div>
        <div class="bar" style="width:${this.data.width}%">
          <span>${this.data.pct}%</span>
        </div>
      </div>
      <div><a class="show-details" href="/analytics/clickDetails/?products[]=${this.data.global_product_basic_id}">${this.translations.details}</a></div>
    `;
  }
}

export default ProductClickStats;