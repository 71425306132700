import '../scss/index.scss';

import {
  MatchHeight,
  SpinnerOverlay,
  VisibilityWatcher
} from 'application/src/js/util.js';

import ProductClickStats from 'application/src/js/analytics/product-click-stats.js';

require.context('mundus-agri/src/images/logos/companies', false, /\.(png|svg|jpe?g|gif)$/);
require.context('mundus-agri/src/images/index', false, /\.(png|svg|jpe?g|gif)$/);

window.addEventListener('DOMContentLoaded', _ => {
  new MatchHeight({
    elements: document.querySelectorAll('section .trade-item .item-title, section .trade-item .item-body'),
    responsive: true
  });

  new VisibilityWatcher({
    elements: [
      document.querySelector('section.responsibles'),
      document.querySelector('section.charts'),
    ]
  });

  if (document.querySelector('#modal-update-account-data')) {
    import( /* webpackChunkName: "modal-update-account-data" */ 'application/src/js/incursio/modal-update-account-data.js')
      .then(({
        default: ModalUpdateAccountData
      }) => {
        const modalUpdateAccountData = new ModalUpdateAccountData();

        modalUpdateAccountData.subscribe('init', _ => {
          if (modalUpdateAccountData.incomplete) {
            const updateReminder = document.querySelector('#update-account-reminder');

            updateReminder.classList.remove('hide');

            updateReminder.querySelector('a').addEventListener('click', _ => {
              modalUpdateAccountData.open();
              updateReminder.classList.add('hide');
            });
          }
        });
      });
  }

  if (document.querySelector('section.import-export')) {
    import( /* webpackChunkName: "import-export-statistics" */ 'mundus-agri/src/js/index/import-export-statistics.js').then(({
      default: ImportExportStatistics
    }) => {
      new ImportExportStatistics({
        selectedLanguage: selectedLanguage,
        loader: new SpinnerOverlay({
          element: document.querySelector('section.import-export .overlay')
        })
      });
    });
  }

  if (document.querySelector('section.recently-registered .glide')) {
    const companySlider = new Glide(document.querySelector('section.recently-registered .glide'), {
      autoplay: 10000,
      type: 'carousel',
      gap: 11,
      perView: 3,
      bound: 0,
      breakpoints: {
        1000: {
          perView: 2,
          gap: 11
        },
        700: {
          perView: 1,
          gap: 0
        }
      }
    }).mount();
    companySlider.on('move.after', _ => bLazy.revalidate());
  }

  if (document.querySelector('section.references .glide')) {
    const referencesSlider = new Glide(document.querySelector('section.references .glide'), {
      autoplay: 10000,
      type: 'carousel',
      gap: 11,
      perView: 5,
      bound: 0,
      breakpoints: {
        990: {
          perView: 4
        },
        750: {
          perView: 3
        },
        560: {
          perView: 2,
          gap: 11
        },
        480: {
          perView: 1,
          gap: 0
        }
      }
    }).mount();
    referencesSlider.on('move.after', _ => bLazy.revalidate());
  }

  if (document.querySelector('section.testimonials .glide')) {
    const testimonialsSlider = new Glide(document.querySelector('section.testimonials .glide'), {
      autoplay: 10000,
      bound: 0,
      gap: 0,
      perMove: 1,
      type: 'carousel'
    }).mount();
    testimonialsSlider.on('move.after', _ => bLazy.revalidate());
  }

  new ProductClickStats({
    translations: translations.productClickStats,
    maxEntries: 5
  });
});